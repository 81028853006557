import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w08GK06Fraenkisch = () => (
  <Layout>
    <SEO title="w08GK06Fraenkisch" />

    <h1 id="ueberschrift">Gemeinsam Kochen</h1>
    <h2>die fränkische Küche &emsp;  ©&nbsp;1997</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w08GK06Fraenkisch&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Alles macht viel mehr Spaß, wenn es zusammen mit anderen erforscht und gekostet werden kann.
        Gemeinsam schnuppern wir in der Küche Ihrer Wahl in die
        fränkische Kochkunst und profitieren von
        den verschiedenen guten sowie weniger schönen Erfahrungen. Genießen Sie die Zeit
        beim geselligen Zubereiten und Vernichten der Speisen welche oft
        mit lustigen und interessanten Anekdoten gewürzt sind.
      </p>
      <p>
        Auf Grund der aktuellen Situation kann dieser Kurs nur "außer Haus" angeboten werden.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitz- oder Stehplatz pro Teilnehmer + mind. 1 Sitz- oder Stehplatz extra<br/>
        Materialbedarf: + je nach Gericht +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und etwas Freiraum
      </p>
      <p>
        Voraussetzung: + Grundkenntnisse im Umgang mit diversen Küchenutensilien + <br />
        + <b>jüngere Teilnehmer:</b> eine Aufsichtsperson
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w08GK06Fraenkisch
